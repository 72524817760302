<template>
  <div class="home">
    <loading :loading="isLoading"></loading>
    <SectionHeader
    :name="$t('TERMS.objectives')"
    description=""
    :loading="isLoading"
    :progress="0"
    />

    <!-- SKELETONS LOADING -->
    <b-row class="main-container py-5 px-md-5" v-if="isLoading">
      <b-col class="mb-3" sm="12" md="6" lg="4" xl="3" v-for="n in Array(12)" v-bind:key="n">
        <CategoryCardSkeleton />
      </b-col>
    </b-row>
    <!-- END SKELETONS LOADING -->

    <div class="main-container py-5">
      <b-row class="px-md-5 no-lateral-margin">
        <b-col class="mb-3" sm="12" md="6" lg="4" xl="3"
        v-for="content in translatedObjectivesFirstLevel"
        v-bind:key="content.id">
          <CategoryCard
            :img="getUrlImg(content.image)"
            :title="content.name"
            :text="content.description"
            :progress="calculateProgress(content.score, content.total_points)"
            :to="{ name: 'objectiveDetail', params: { id: content.id, name: content.title } }"
          >
          </CategoryCard>
        </b-col>
      </b-row>

      <NoResults v-if="objectivesFirstLevel.length === 0"/>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CategoryCard from '@/components/utils/cards/CategoryCard'
import CategoryCardSkeleton from '@/components/skeletons/CategoryCardSkeleton'
import SectionHeader from '@/components/SectionHeader'
import NoResults from '@/components/utils/NoResults'

import mapper from '@/services/mixins/mapper.mixins'

import ProgressMixin from '@/services/mixins/progress.mixins'

import i18nmixin from '@/services/mixins/i18n.mixins'

export default {
  name: 'Objectives',
  mixins: [ProgressMixin, mapper, i18nmixin],
  components: { CategoryCard, SectionHeader, CategoryCardSkeleton, NoResults },
  data () {
    return {
    }
  },
  methods: {
    ...mapActions({
      getObjectivesFirstLevel: 'objectives/getObjectivesFirstLevel'
    })
  },
  computed: {
    ...mapGetters({
      objectivesFirstLevel: 'objectives/getObjectivesFirstLevel',
      isLoading: 'objectives/isLoading'
    }),
    translatedObjectivesFirstLevel () { return this.translateObjectArray(this.objectivesFirstLevel) }
  },
  mounted () {
    this.getObjectivesFirstLevel()
  }
}
</script>
